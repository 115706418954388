import gql from 'graphql-tag';
import {
  deviceFragment,
  placeFragment,
  placeInterlockableFragment,
  deviceRuleFragment,
  condominiumRuleTypeFragment,
  condominiumRuleCategoryFragment,
  condominiumPlaceCamerasFragment,
  condominiumAccessHoursFragment,
  condominiumHistoryNotesFragment,
  condominiumWorkersFragment,
} from './fragments';

// AccessProfile
export const accessProfiles = gql`
  query accessProfiles($personContextId: Int!, $onlyNoInvites: Boolean!) {
    accessProfiles(
      personContextId: $personContextId
      onlyNoInvites: $onlyNoInvites
    ) {
      id
      personId
      name
      description
      isInvite
      haveVehicleDevice
      serializedParams
    }
  }
`;

export const accessProfilesByTreeNodeId = gql`
  query accessProfilesByTreeNodeId($sourceNodeId: Int!) {
    accessProfilesByTreeNodeId(sourceNodeId: $sourceNodeId) {
      id
      personId
      name
      description
    }
  }
`;

// AccessRoute
export const accessProfilePlaces = gql`
  query accessProfilePlaces($accessProfileId: Int!) {
    accessProfilePlaces(accessProfileId: $accessProfileId) {
      id
      name
      treeNodeId
      placeType {
        id
        name
      }
      haveVehicleDevice
      serializedParams
    }
  }
`;

// Modal/PutPersonAccessProfile
export const accessProfilePlaceSelect = gql`
  query accessProfilePlaceSelect($accessProfileId: Int!) {
    accessProfilePlaceSelect(accessProfileId: $accessProfileId) {
      value
      label
      isVehicular
      canBeRemoteOpened
    }
  }
`;

// DetailProvider
export const condominium = gql`
  query condominium($personContextId: Int!) {
    condominium(personContextId: $personContextId) {
      name
      email
      phone
      treeNodeId
      personId
      personContextId
      params {
        value
        name
        personContextId
      }
      unityGroupType {
        id
        label
      }
      unityType {
        id
        label
      }
      addresses {
        id
        addressTypeId
        streetName
        zipNumber
        addressNumber
        isPrincipal
        district {
          value
          label
        }
        city {
          value
          label
        }
        region {
          value
          label
        }
        country {
          value
          label
        }
      }
    }
  }
`;

// DeviceList
export const placeDevices = gql`
  query placeDevices($placeId: Int!, $includes: Includes) {
    placeDevices(placeId: $placeId, includes: $includes) {
      ...deviceParts
    }
  }
  ${deviceFragment}
`;

export const condominiumPlaces = gql`
  query condominiumPlaces($condominiumId: Int!) {
    condominiumPlaces(condominiumId: $condominiumId) {
      ...placeInterlockableParts
    }
  }
  ${placeInterlockableFragment}
`;

export const interlockablePlaces = gql`
  query condominiumInterlockablePlaces(
    $condominiumId: Int!
    $interlockedPlaceId: Int
  ) {
    interlockablePlaces: condominiumInterlockablePlaces(
      condominiumId: $condominiumId
      interlockedPlaceId: $interlockedPlaceId
    ) {
      ...placeParts
    }
  }
  ${placeFragment}
`;

export const interlockedPlace = gql`
  query interlockedPlace($interlockId: Int!) {
    interlockedPlace(interlockId: $interlockId) {
      id
      name
      creationDate
      places {
        place {
          id
        }
      }
    }
  }
`;

export const placeAvailableDevices = gql`
  query placeAvailableDevices($placeId: Int!) {
    placeAvailableDevices(placeId: $placeId) {
      condominiumDevices {
        ...deviceParts
      }
      partnerDevices {
        ...deviceParts
      }
    }
  }
  ${deviceFragment}
`;

// GeneralData
export const placeTypeLabel = gql`
  query placeTypeLabels($filters: PlaceTypeLabelQueryInput!) {
    placeTypeLabels(filters: $filters) {
      unityGroupType
      unityType
    }
  }
`;

export const placeTypes = gql`
  query placesType($onlyForCondominium: Boolean) {
    placesTypeSelect(onlyForCondominium: $onlyForCondominium) {
      value
      label
    }
  }
`;

// Parameters
export const places = gql`
  query places($filters: PlaceQueryInput!) {
    places(filters: $filters) {
      collection {
        id
        name
        placeId
        placeTypeId
        comments
        serializedParams
        placeType {
          id
          name
          fieldName
        }
      }
    }
  }
`;

export const place = gql`
  query place($placeId: ID) {
    place(placeId: $placeId) {
      id
      name
      placeId
      comments
      placeTypeLabel {
        id
        label
      }
      placeTypeLabelId
      placeType {
        id
        name
        fieldName
      }
      serializedParams
      treeNodeId
    }
  }
`;

// Modal/PutDevice
export const checkSerialNumber = gql`
  query deviceCheckSerialNumber($placeId: Int!, $serialNumber: String!) {
    deviceCheckSerialNumber(placeId: $placeId, serialNumber: $serialNumber) {
      ableToUse
      reason
      device {
        id
        placeId
        deviceType {
          id
          name
          fieldName
          isCpu
          isController
        }
        serialNumber
        ip
        protocolVersion
        firmwareVersion
        name
        isOnline
      }
      cpuDevices {
        id
        name
        placeId
        deviceType {
          id
          name
        }
        serialNumber
        ip
        isOnline
      }
    }
  }
`;

// Modal/PutDevice
export const getCpuDevices = gql`
  query cpuDevices($placeId: Int!) {
    deviceCpus(placeId: $placeId) {
      id
      name
      placeId
      deviceTypeId
      serialNumber
      ip
      isOnline
    }
  }
`;
export const availableParents = gql`
  query deviceAvailableParents($deviceId: Int!, $placeId: Int!) {
    deviceAvailableParents(deviceId: $deviceId, placeId: $placeId) {
      ...deviceParts
    }
  }
  ${deviceFragment}
`;

export const availablePlaces = gql`
  query deviceAvailablePlaces($deviceId: Int!, $placeId: Int!) {
    deviceAvailablePlaces(deviceId: $deviceId, placeId: $placeId) {
      ...placeParts
    }
  }
  ${placeFragment}
`;

export const condominiumNotes = gql`
  query condominiumNotes($condominiumId: String) {
    condominiumNotes(condominiumId: $condominiumId) {
      id
      name
      value
    }
  }
`;

export const personContextParams = gql`
  query personContextParams($personContextId: Int!, $paramName: String!) {
    personContextParams(
      personContextId: $personContextId
      paramName: $paramName
    ) {
      id
      name
      value
    }
  }
`;

export const condominiumPlaceUnities = gql`
  query condominiumPlaceUnities(
    $targetType: String
    $sourceNodeId: ID
    $filters: UnitiesFiltersInput
  ) {
    condominiumPlaceUnities(
      targetType: $targetType
      sourceNodeId: $sourceNodeId
      filters: $filters
    ) {
      collection {
        id
        name
        treeNodeId
        placeTypeId
        placeTypeLabelId
        serializedParams {
          extension
          prefixPABX
          codeAta
        }
      }
      pagination {
        totalPages
        totalResults
        page
        pageSize
      }
    }
  }
`;

export const deviceRules = gql`
  query deviceRules($deviceId: Int!) {
    deviceRules(deviceId: $deviceId) {
      ...deviceRuleParts
    }
  }
  ${deviceRuleFragment}
`;

export const listPatrolSettings = gql`
  query listPatrolSettings($condominiumId: ID) {
    listPatrolSettings(condominiumId: $condominiumId) {
      id
      condominiumId
      days
      frequency
      start {
        hours
        minutes
      }
      end {
        hours
        minutes
      }
      startTime
      endTime
      creationDate
    }
  }
`;

export const listDeviceFirmwares = gql`
  query listDeviceFirmwares(
    $condominiumId: ID
    $deviceType: String
    $chipsetType: String
  ) {
    listDeviceFirmwares(
      condominiumId: $condominiumId
      deviceType: $deviceType
      chipsetType: $chipsetType
    ) {
      id
      changelog
      version
      releaseType
      releaseDate
      releaseStatus
      allowDowngrade
      chipsetType
    }
  }
`;

export const listCurrentFirmwares = gql`
  query listCurrentFirmwares {
    listCurrentFirmwares {
      id
      version
      releaseStatus
      deviceType
      chipsetType
    }
  }
`;

export const condominiumRules = gql`
  query condominiumRules($condominiumId: Int!) {
    condominiumRules(condominiumId: $condominiumId) {
      ...condominiumRuleTypeParts
    }
  }
  ${condominiumRuleTypeFragment}
`;

export const condominiumRulesCategories = gql`
  query condominiumRulesCategories {
    condominiumRulesCategories {
      ...condominiumRuleCategoryParts
    }
  }
  ${condominiumRuleCategoryFragment}
`;

export const condominiumPlaceCameras = gql`
  query condominiumPlaceCameras($placeId: Int!) {
    condominiumPlaceCameras(placeId: $placeId) {
      ...condominiumPlaceCamerasPart
    }
  }
  ${condominiumPlaceCamerasFragment}
`;

export const condominiumPlaceDvrCameras = gql`
  query condominiumPlaceDvrCameras($placeId: Int!) {
    condominiumPlaceDvrCameras(placeId: $placeId) {
      ...condominiumPlaceCamerasPart
    }
  }
  ${condominiumPlaceCamerasFragment}
`;

export const getCondominiumActivation = gql`
  query condominiumActivation($condominiumId: Int!) {
    condominiumActivation(condominiumId: $condominiumId) {
      name
      creationDate
    }
  }
`;

export const eventPushNotificationSetting = gql`
  query eventPushNotificationSetting(
    $condominiumContextId: String!
    $eventId: String!
  ) {
    eventPushNotificationSetting(
      condominiumContextId: $condominiumContextId
      eventId: $eventId
    ) {
      redirectToDweller
    }
  }
`;

export const eventsPushNotificationSetting = gql`
  query eventsPushNotificationSetting($condominiumContextId: String!) {
    eventsPushNotificationSetting(condominiumContextId: $condominiumContextId) {
      redirectToDweller
      eventId
    }
  }
`;

export const getCondominiumAccessHours = gql`
  query condominiumAccessHours($condominiumPersonContextId: Int!) {
    condominiumAccessHours(
      condominiumPersonContextId: $condominiumPersonContextId
    ) {
      ...condominiumAccessHoursParts
    }
  }
  ${condominiumAccessHoursFragment}
`;

export const condominiumHistoryNotes = gql`
  query condominiumHistoryNotes($condominiumPersonContextId: ID!) {
    condominiumHistoryNotes(
      condominiumPersonContextId: $condominiumPersonContextId
    ) {
      ...condominiumHistoryNotesParts
    }
  }
  ${condominiumHistoryNotesFragment}
`;

export const condominiumHistoryNoteCategories = gql`
  query condominiumHistoryNoteCategories {
    condominiumHistoryNoteCategories {
      id
      name
    }
  }
`;

export const getCondominiumWorkers = gql`
  query condominiumWorkers($condominiumPersonContextId: ID!) {
    condominiumWorkers(
      condominiumPersonContextId: $condominiumPersonContextId
    ) {
      ...condominiumWorkersParts
    }
  }
  ${condominiumWorkersFragment}
`;
